import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http'
import { Observable, of, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';
import Swal from 'sweetalert2'

import { COMPANIES } from '../company.json';
import { RESPONSE_TEST } from '../responseTest.json';
import { Company } from '../company';
import { JsonResponse } from '../json-response';
import { SearchDocument } from '../search/search-document';

@Injectable({
    providedIn: 'root'
})
export class CompanyService {

    private httpHeaders = new HttpHeaders({ 'Content-Type': 'application/json' })
    private urlEndPoint: string = 'https://sistemas.digitalinka.com/cpe/api/v1';
    //private urlEndPoint: string = 'http://localhost:8080/api/v1';

    constructor(private http: HttpClient) {

    }

    getCompanyByRuc(ruc: string): Observable<Company> {
        return of(COMPANIES.filter(company => company.ruc == ruc)[0]);
    }

    searchDocument(formulario: any): Observable<JsonResponse> {
        return this.http.post<JsonResponse>(this.urlEndPoint + '/search', formulario,
            { headers: this.httpHeaders }).pipe(
                catchError(e => {
                    if (e.status != 0) {
                        Swal.fire("Error", e.error.message, 'error');
                    } else {
                        Swal.fire("Error", e.message, 'error');
                    }
                    return throwError(e);
                })
            );
        //return of(RESPONSE_TEST);
    }

    downloadDocument(typeFile: string) {
        let url = '';
        switch (typeFile) {
            case "xml":
                url = "/download/xml";
                break;
            case "pdf":
                url = "/download/pdf";
                break;
            case "cdr":
                url = "/download/cdr";
                break;
        }
        return this.urlEndPoint + url;
    }

    getFiles(typeFile: string, searchDocument: SearchDocument) {
        const headers = new HttpHeaders({'Bearer': ''});
        return this.http.post(
                this.urlEndPoint + "/download/"+typeFile, searchDocument,
                { headers: headers, responseType: "blob",  observe: 'response'});
        /*return this.http.get(
            this.urlEndPoint + "/download/xml", { headers: headers, responseType: "blob",  observe: 'response'});
            */
    }
}