import { Component, Input } from '@angular/core';

import { Company } from '../company';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html'
})
export class HeaderComponent {

  @Input() company: Company;

  constructor() { }

}
