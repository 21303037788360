<app-header [company]="company"></app-header>
  <br>
  <div class="container">
    <div class="row">
      <div class="col-sm-2">
  
      </div>
      <div class="col-sm-8">
        <div class="card">
          <div class="card-header bg-primary text-white">
            <h5 class="my-0">
              Buscar
            </h5>
          </div>
          <div class="card-body">
            <form autocomplete="off"  [formGroup]="searchForm" (ngSubmit)="search()">
              <div class="form-body">
                <div class="row">
                  <div class="col-md-6">
                    <div class="form-group">
                      <label class="control-label mt-2">Tipo Documento<span class="text-danger"> *</span></label>
                      <select class="form-control"
                      formControlName="documentType" 
                      [class.is-invalid]="documentTypeNotValid"
                       >
                        <option *ngFor="let item of document_types" [value]="item.sunat_code">{{item.name}}</option>
                      </select>
                      <small class="form-control-feedback"></small>
                    </div>
                  </div>
                  <div class="col-md-6">
                    <div class="form-group">
                      <label class="control-label mt-2">Fecha de emisión<span class="text-danger"> *</span></label>
                      <div class="input-group">
                        <input type="text" 
                        class="form-control" 
                        [class.is-invalid]="issueDateNotValid"
                        formControlName="issueDate" 
                        ngbDatepicker #d="ngbDatepicker"
                        placeholder="yyyy-mm-dd">
                        <div class="input-group-append">
                          <button class="btn btn-outline-secondary" (click)="d.toggle()" type="button">
                            <fa-icon [icon]="faCalendarAlt"></fa-icon>
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="row">
                  <div class="col-md-6">
                    <div class="form-group">
                      <label class="control-label mt-2">Serie<span class="text-danger"> *</span></label>
                      <input type="text" 
                      class="form-control"
                      formControlName="serie"
                      [class.is-invalid]="serieNotValid"
                       />
                      <small class="form-control-feedback">Ej. F001</small>
                    </div>
                  </div>
                  <div class="col-md-6">
                    <div class="form-group">
                      <label class="control-label mt-2">Número<span class="text-danger"> *</span></label>
                      <input type="text" 
                      formControlName="number" 
                      class="form-control"
                      [class.is-invalid]="numberNotValid"
                      />
                      <small class="form-control-feedback" >Ej. 1</small>
                    </div>
                  </div>
                </div>
                <div class="row">
                  <div class="col-md-6">
                    <div class="form-group">
                      <label class="control-label mt-2">Número Cliente (RUC/DNI/CE)<span class="text-danger">
                          *</span></label>
                      <input type="text" maxlength="11" class="form-control"/>
                      <small class="form-control-feedback" ></small>
                    </div>
                  </div>
                  <div class="col-md-6">
                    <div class="form-group" >
                      <label class="control-label mt-2">Monto total<span class="text-danger"> *</span></label>
                      <input type="text" class="form-control"/>
                      <small class="form-control-feedback"  ></small>
                    </div>
                  </div>
                </div>
              </div>
              <div class="form-actions text-right pt-2 mt-2">
                <button class="btn btn-primary" type="submit">Buscar</button>
              </div>
            </form>
            <form id="frm_download" action="{{url}}" method="GET">
            <div class="table-responsive" *ngIf="record">
              <table class="table">
                <thead>
                  <tr>
                    <th>Fecha</th>
                    <th>Numeración</th>
                    <th>Descargas</th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td>{{ record.issue_date }}</td>
                    <td>{{ record.numeration }}</td>
                    <td>
                      <a [routerLink]="" (click)='download("xml")' >
                          <fa-icon [icon]="faFileCode"></fa-icon> XML</a>&nbsp;
                      <a [routerLink]="" (click)='download("pdf")' >
                        <fa-icon [icon]="faFilePdf"></fa-icon> PDF</a>&nbsp;
                      <a [routerLink]="" (click)='download("cdr")' >
                            <fa-icon [icon]="faFileCode"></fa-icon> CDR</a>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
            </form>
          </div>
        </div>
      </div>
      <div class="col-sm-2">
  
      </div>
    </div>
  </div>