import { Component } from '@angular/core';
import { NgbDateParserFormatter } from '@ng-bootstrap/ng-bootstrap';
import { faFilePdf, faFileCode, faCalendarAlt } from '@fortawesome/free-solid-svg-icons';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import Swal from 'sweetalert2';
import { ActivatedRoute } from '@angular/router';

import { DocumentType } from './../document-type';
import { SearchDocument } from './search-document';
import { CompanyService } from '../services/company.service';
import { Company } from './../company';
import { JsonResponse } from './../json-response';
import { UtilService } from "../services/util.service";



@Component({
  selector: 'app-search',
  templateUrl: './search.component.html'
})
export class SearchComponent {

  searchForm: FormGroup;
  searchDocument= new SearchDocument();
  faFileCode = faFileCode;
  faFilePdf = faFilePdf;
  faCalendarAlt = faCalendarAlt;
  company: Company;
  document_types: DocumentType[] = [
    { sunat_code: '01', name: 'Factura Electrónica' },
    { sunat_code: '03', name: 'Boleta Electrónica' },
    { sunat_code: '07', name: 'Nota de Crédito Electrónica' },
    { sunat_code: '08', name: 'Nota de Débito Electrónica' }
  ]
  record: any = null;
  jsonResponse: JsonResponse;
  url: string = "";

  constructor(
    private utilService: UtilService,
    private activeRoute: ActivatedRoute,
    private companyService: CompanyService,
    private parserFormatter: NgbDateParserFormatter,
    private formBuilder: FormBuilder
  ) {
    this.getCompany();
    this.createForm();
  }

  getCompany():void{
    this.activeRoute.params.subscribe(params => {
      let ruc = params['ruc'];
      if (ruc) {
        this.companyService.getCompanyByRuc(ruc).subscribe(company => this.company = company);
      }
    });
  }

  createForm():void{
    this.searchForm= this.formBuilder.group({
      documentType: ['', Validators.required],
      issueDate: ['', Validators.required],
      serie: ['', [Validators.required, Validators.minLength(4)]],
      number: ['', [Validators.required, Validators.minLength(1)]],
    });
  }

  get documentTypeNotValid(){
    return this.searchForm.get('documentType').invalid && this.searchForm.get('documentType').touched;
  }

  get issueDateNotValid(){
    return this.searchForm.get('issueDate').invalid && this.searchForm.get('issueDate').touched;
  }

  get serieNotValid(){
    return this.searchForm.get('serie').invalid && this.searchForm.get('serie').touched;
  }

  get numberNotValid(){
    return this.searchForm.get('number').invalid && this.searchForm.get('number').touched;
  }

  search(): void {
    if(!this.searchForm.valid){
      Object.values(this.searchForm.controls).forEach(control=>{
        control.markAsTouched();
      });
      return;
    }
    this.record = null;
    Swal.fire({icon:'info', text:'Espere por favor...', allowOutsideClick: false});
    Swal.showLoading();
    this.searchDocument.companyRuc= this.company.ruc;
    this.searchDocument.documentType= this.searchForm.value.documentType;
    this.searchDocument.issueDate= this.parserFormatter.format(this.searchForm.value.issueDate);
    this.searchDocument.serie= this.searchForm.value.serie;
    this.searchDocument.number= this.searchForm.value.number;
    console.log(this.searchDocument);
    this.companyService.searchDocument(this.searchDocument).subscribe(r => {
        if (r.success) {
          this.record = r.data;
          Swal.fire({icon: 'success', text: `Documento encontrado`});
        } else {
          Swal.fire({icon: 'error', text: r.message});
        }
      });
  }

  download(typeFile: string): void{
    switch(typeFile){
      case "pdf":
        this.searchDocument.downloadLink= this.record.download_pdf;
        break;
      case "xml":
        this.searchDocument.downloadLink= this.record.download_xml;
        break;
      case "cdr":
        this.searchDocument.downloadLink= this.record.download_cdr;
        break;
    }
      this.companyService.getFiles(typeFile,this.searchDocument).subscribe((r:any)=>{
        this.utilService.downloadFile(r);
      }, error=>{
        Swal.fire({icon: 'error', text: 'No existe el archivo solicitado.'});
      });
  }
}